
































import { computed, defineComponent, ref } from "@vue/composition-api";
import { VueEditor } from "vue2-editor";

import Dropzone from "@/app/components/Base/Dropzone.vue";
import ProjectActionsPanel from "@/app/components/Project/ProjectDescription/ProjectActionsPanel.vue";
import ProjectDescription from "@/app/components/Project/ProjectDescription/ProjectDescription.vue";
import ProjectPageWrapper from "@/app/components/Project/ProjectPageWrapper.vue";
import ProjectResultEditor from "@/app/components/Project/ProjectResult/ProjectResultEditor.vue";
import { encodeAttachmentInput } from "@/models/AttachmentInput";
import { projectActionPanelEnum } from "@/shared/enums/components/Project/ProjectDescription/projectActionPanel.enum";
import { useNotification } from "@/shared/hooks/notification";
import { useProjectActions } from "@/shared/hooks/project/useProjectActions";
import { updateProjectResult } from "@/shared/requests";
import { ProjectResultInputExt } from "@/shared/types/components/Project/Project";

enum ProjectResultModeEnum {
  VIEW = 'view',
  EDIT = 'edit'
}

export default defineComponent({
  name: "project-result",
  components: {
    ProjectPageWrapper,
    ProjectActionsPanel,
    ProjectDescription,
    VueEditor,
    Dropzone,
    ProjectResultEditor,
  },
  setup() {
    const { errNotification, successNotification } = useNotification();

    const projectResultModeDefaultValue = ref(ProjectResultModeEnum.VIEW);

    const {
      handlerArchive,
      handlerDelete,
      handleUnzip,
      innerProject,
      editProjectStatus,
      loading,
      statusIsUpdating,
      handleEdit,
      projectId,
      refetch,
    } = useProjectActions();

    const projectResultInput = computed(() => {
      return {
        body: innerProject.value.result,
        attachmentInputs: innerProject.value.resultAttachments,
      };
    });

    const projectResultMode = computed({
      get() {
        if (!innerProject.value.result && !innerProject.value.resultAttachments.length) return ProjectResultModeEnum.EDIT;
        return projectResultModeDefaultValue.value;
      },
      set(val: ProjectResultModeEnum) {
        projectResultModeDefaultValue.value = val;
      },
    });

    async function editResult(projectResultInput: ProjectResultInputExt) {
      try {
        await updateProjectResult({
          projectResultInput: {
            ...projectResultInput,
            attachmentInputs: encodeAttachmentInput(projectResultInput.attachmentInputs), 
          },
          projectId,
        });
        successNotification(`Описание проекта ${innerProject.value.name} изменено`);
        projectResultMode.value = ProjectResultModeEnum.VIEW;
        refetch && refetch();
      } catch (err) {
        console.error(err);
        errNotification(err);
      }
    }

    return {
      projectActionPanelEnum,
      innerProject,
      statusIsUpdating,
      editProjectStatus,
      handleEdit,
      handlerArchive,
      handlerDelete,
      handleUnzip,
      loading,
      projectResultInput,
      editResult,
      projectResultMode,
      ProjectResultModeEnum,
      projectResultModeDefaultValue,
    };
  },
});
