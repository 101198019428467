



























import { computed, defineComponent, PropType, ref, watch } from "@vue/composition-api";
import { VueEditor } from "vue2-editor";

import Dropzone from "@/app/components/Base/Dropzone.vue";
import { PROJECT_RESULT_DATA } from "@/shared/constants/components/Project/ProjectForm";
import { projectActionPanelEnum } from "@/shared/enums/components/Project/ProjectDescription/projectActionPanel.enum";
import { ProjectResultInputExt } from "@/shared/types/components/Project/Project";

export default defineComponent({
  name: "ProjectResultEditor",
  components: {
    VueEditor,
    Dropzone,
  },
  props: {
    value: {
      type: Object as PropType<ProjectResultInputExt>,
      default: null,
    },
  },
  setup(props) {

    const innerValue = ref<ProjectResultInputExt>(_.cloneDeep(PROJECT_RESULT_DATA));
    const disabled = computed(() => !innerValue.value.body && !innerValue.value.attachmentInputs.length);


    watch(
      () => props.value as ProjectResultInputExt,
      (val) => {
        if (!val) return;
        innerValue.value = _.cloneDeep(val);
      },
      {
        deep: true,
        immediate: true, 
      },
    );

    return {
      innerValue,
      projectActionPanelEnum,
      disabled,
    };
  },
});
